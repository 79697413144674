<script>
import Layout from "@/router/layouts/crm";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: null,
      loading: true,
      success: false,
    };
  },
  methods: {
    getUnsubscribe() {
      api
        .get('crm/unsubscribe/' + this.email)
        .then((response) => {
          if (response.data.status == 'success') {
            this.success = true
          }

          this.loading = false
        })
    },
  },
  mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email
      this.getUnsubscribe();
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center mt-5">
      <div class="col-md-7 col-lg-5 col-xl-4">
        <template v-if="loading">
          <div class="bg-white rounded p-5 text-center">
            <span aria-hidden="true" class="m-2 spinner-border text-dark"></span>
          </div>
        </template>
        <template v-else-if="!success">
          <div class="bg-white rounded p-5">
            <img class="mb-4" style="max-width:50px;" src="@/assets/images/logo-icon.png" />
            <h5 class="mb-0 font-weight-bold text-dark">Não foi possível cancelar a inscrição, tente novamente ou entre em contato.</h5>
          </div>
        </template>
        <template v-else-if="success">
          <div class="bg-white rounded p-5">
            <img class="mb-4" style="max-width:50px;" src="@/assets/images/logo-icon.png" />
            <h4 class="mb-3 font-weight-bold text-dark">Sua inscrição foi cancelada!</h4>
            <h5 class="mb-0 font-weight-normal text-dark">Você foi descadastrado desses e-mails.</h5>
          </div>
        </template>
        <div class="m2n text-center mt-4 text-uppercase">
          <a class="text-muted" target="_blank" href="https://m2n.com.br/?utm_source=newstreaming.app&utm_medium=link&utm_campaign=logo">E-mail marketing por M2N</a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.m2n {
  font-size: .7em;
  letter-spacing: 2px;
}
</style>